import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
const API_URL =
  window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
    ? "http://127.0.0.1:5000/api/"
    : "https://monitoring.thereportfinder.com/api/";
const axiosInstance = axios.create({ baseURL: API_URL });
const ApiContext = React.createContext();

export function useApi() {
  return useContext(ApiContext);
}

export function ApiProvider({ children }) {
  const { getUserToken } = useAuth();
  async function GetTableData(data) {
    const userToken = await getUserToken();
    const headers = { Authorization: "Bearer " + userToken };
    const r = await axiosInstance.post("table-data", data, {
      headers: headers,
    });
    if (r.status === 200) {
      return r.data;
    }
  }
  async function UpdateWeekdays(data) {
    const userToken = await getUserToken();
    const headers = { Authorization: "Bearer " + userToken };
    const r = await axiosInstance.post("update-monitoring", data, {
      headers: headers,
    });
    if (r.status === 200) {
      return r.data;
    }
  }
  async function RunJupyterOnRemote() {
    try {
      const userToken = await getUserToken();
      const headers = { Authorization: "Bearer " + userToken };
      const r = await axiosInstance.get("run-jupyter", { headers: headers });
      if (r.status === 200) {
        return r.data;
      }
    } catch (error) {
      console.error(error);
      return "An error occurred"; // You
    }
  }
  async function UpdateServerDetails(data) {
    const userToken = await getUserToken();
    const headers = { Authorization: "Bearer " + userToken };
    const r = await axiosInstance.post("update-server-details", data, {
      headers: headers,
    });
    if (r.status === 200) {
      return r.data;
    }
  }
  async function runSelectedFunction(data) {
    const userToken = await getUserToken();
    const headers = { Authorization: "Bearer " + userToken };
    const r = await axiosInstance.post("run-function-by-name", data, {
      headers: headers,
    });
    if (r.status === 200) {
      return r.data;
    }
  }

  async function getServerCreds() {
    const userToken = await getUserToken();
    const headers = { Authorization: "Bearer " + userToken };
    const r = await axiosInstance.get("servers-page", { headers: headers });
    if (r.status === 200) {
      return r.data;
    }
  }
  async function serverCrudOps(data) {
    try {
      const userToken = await getUserToken();
      const headers = { Authorization: "Bearer " + userToken };
      const r = await axiosInstance.post("servers-page", data, {
        headers: headers,
      });
      if (r.status === 200) {
        return r.data;
      }
    } catch (error) {
      // Handle the error here
      console.error(error);
      return "An error occurred"; // You can return a specific value or object here
    }
  }
  async function getproxiesData() {
    try {
      const userToken = await getUserToken();
      const headers = { Authorization: "Bearer " + userToken };
      const r = await axiosInstance.get("proxies-page", { headers: headers });
      if (r.status === 200) {
        return r.data;
      }
    } catch (error) {
      // Handle the error here
      console.error(error);
      return "An error occurred"; // You can return a specific value or object here
    }
  }
  async function proxiesCrudOps(data) {
    const userToken = await getUserToken();
    const headers = { Authorization: "Bearer " + userToken };
    const r = await axiosInstance.post("proxies-page", data, {
      headers: headers,
    });
    if (r.status === 200) {
      return r.data;
    }
  }

  async function getCaptcaData() {
    const userToken = await getUserToken();
    const headers = { Authorization: "Bearer " + userToken };
    const r = await axiosInstance.get("captcha-page", { headers: headers });
    if (r.status === 200) {
      return r.data;
    }
  }
  async function captchaCrudOps(data) {
    const userToken = await getUserToken();
    const headers = { Authorization: "Bearer " + userToken };
    const r = await axiosInstance.post("captcha-page", data, {
      headers: headers,
    });
    if (r.status === 200) {
      return r.data;
    }
  }

  async function AddUser(data) {
    const userToken = await getUserToken();
    const headers = { Authorization: "Bearer " + userToken };
    const r = await axiosInstance.post("signup", data, { headers: headers });
    if (r.status === 200) {
      return r.data;
    }
  }

  async function UpdateUserRole(data) {
    const userToken = await getUserToken();
    const headers = { Authorization: "Bearer " + userToken };
    const r = await axiosInstance.post("update-user-role", data, { headers: headers });
    if (r.status === 200) {
      return r.data;
    }
  }


  async function getJurisdictions(data) {
    const userToken = await getUserToken();
    const headers = { Authorization: "Bearer " + userToken };
    const r = await axiosInstance.post("jurisdictions", data, {
      headers: headers,
    });
    if (r.status === 200) {
      return r.data;
    }
  }
  async function getUsersList() {
    try {
      const userToken = await getUserToken();
      const headers = { Authorization: "Bearer " + userToken };
      const r = await axiosInstance.post("users-list", {}, { headers: headers });
      if (r.status === 200) {
        return r.data;
      }
    } catch (error) {
      // Handle the error here
      console.error(error);
      return "An error occurred"; // You can return a specific value or object here
    }
  }
  async function UpdateTrackingDetails(data) {
    try {
      const userToken = await getUserToken();
      const headers = { Authorization: "Bearer " + userToken };
      const r = await axiosInstance.post("tracking-data-update", data, {
        headers: headers,
      });
      if (r.status === 200) {
        return r.data;
      }
    } catch (error) {
      // Handle the error here
      console.error(error);
      return "An error occurred"; // You can return a specific value or object here
    }
  }

  async function userManagement(data) {
    try {
      const userToken = await getUserToken();
      const headers = { Authorization: "Bearer " + userToken };
      const r = await axiosInstance.post("user-management", data, {
        headers: headers,
      });
      if (r.status === 200) {
        return r.data;
      }
    } catch (error) {
      // Handle the error here
      console.error(error);
      return "An error occurred"; // You can return a specific value or object here
    }
  }

  async function GetUsersDetails(data) {
    try {
      const userToken = await getUserToken();
      const headers = { Authorization: "Bearer " + userToken };
      const r = await axiosInstance.post("user-details", data, {
        headers: headers,
      });
      if (r.status === 200) {
        return r.data;
      }
    } catch (error) {
      console.error(error);
      return "An error occurred"; // You can return a specific value or object here
    }
  }

  async function getServerStatus(data) {
    try {
      const userToken = await getUserToken();
      const headers = { Authorization: "Bearer " + userToken };
      const r = await axiosInstance.post("check-rdp-status", {}, {
        headers: headers,
      });
      if (r.status === 200) {
        return r.data;
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }

  async function getJurisdictionsPageData() {
    try {
      const userToken = await getUserToken();
      const headers = { Authorization: "Bearer " + userToken };
      const r = await axiosInstance.get("get-jurisdictions-page-data", {
        headers: headers,
      });
      if (r.status === 200) {
        return r.data;
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
  async function changeJurisdictioStatus(data) {
    try {
      const userToken = await getUserToken();
      const headers = { Authorization: "Bearer " + userToken };
      const r = await axiosInstance.post("get-jurisdictions-page-data", data, {
        headers: headers,
      });
      if (r.status === 200) {
        return r.data;
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
  async function getJurisdictionNotes(data) {
    try {
      const userToken = await getUserToken();
      const headers = { Authorization: "Bearer " + userToken };
      const r = await axiosInstance.post("get-jurisdictions-notes", data, {
        headers: headers,
      });
      if (r.status === 200) {
        return r.data;
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }

  async function UpdateNotesOrGitLink(data) {
    try {
      const userToken = await getUserToken();
      const headers = { Authorization: "Bearer " + userToken };
      const r = await axiosInstance.post(
        "update-jurisdictions-notes-gitlink",
        data, { headers: headers }
      );
      if (r.status === 200) {
        return r.data;
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
  async function GetWebsiteLinks(data) {
    try {
      const userToken = await getUserToken();
      const headers = { Authorization: "Bearer " + userToken };
      const r = await axiosInstance.post("websites-by-state", data, {
        headers: headers,
      });
      if (r.status === 200) {
        return r.data;
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
  async function GetTotalWebistesAndCategories(data) {
    try {
      const userToken = await getUserToken();
      const headers = { Authorization: "Bearer " + userToken };
      const r = await axiosInstance.post("websites-by-state", data, {
        headers: headers,
      });
      if (r.status === 200) {
        return r.data;
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
  async function GetMonitorsAndDevelopers() {
    try {
      const userToken = await getUserToken();
      const headers = { Authorization: "Bearer " + userToken };
      const r = await axiosInstance.get("get-monitors-developers", {
        headers: headers,
      });
      if (r.status === 200) {
        return r.data;
      }
    } catch (error) {
      console.log(error);
      throw new Error("An error occurred");
    }
  }

  async function getJurisdictionsByUser() {
    try {
      const userToken = await getUserToken();
      const headers = { Authorization: "Bearer " + userToken };
      const r = await axiosInstance.get("get-user-jurisdictions", {
        headers: headers,
      });
      if (r.status === 200) {
        return r.data;
      }
    } catch (error) {
      console.log(error);
      throw new Error("An error occurred");
    }
  }
  async function UpdateUsername(data) {
    try {
      const userToken = await getUserToken();
      const headers = { Authorization: "Bearer " + userToken };
      const r = await axiosInstance.post("update-user-name", data, {
        headers: headers,
      });
      if (r.status === 200) {
        return r.data;
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
  async function getFilteredCounts(data) {
    try {
      const userToken = await getUserToken();
      const headers = { Authorization: "Bearer " + userToken };
      const r = await axiosInstance.post("websites-by-state", data, {
        headers: headers,
      });
      if (r.status === 200) {
        return r.data;
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }

  async function getmailserviceData() {
    const userToken = await getUserToken();
    const headers = { Authorization: "Bearer " + userToken };
    const r = await axiosInstance.get("mailing_service", { headers: headers });
    if (r.status === 200) {
      return r.data;
    }
  }
  async function mailingServices(data) {
    const userToken = await getUserToken();
    const headers = { Authorization: "Bearer " + userToken };
    const r = await axiosInstance.post("mailing_service", data, {
      headers: headers,
    });
    if (r.status === 200) {
      return r.data;
    }
  }

  const value = {
    GetTableData,
    UpdateWeekdays,
    RunJupyterOnRemote,
    UpdateServerDetails,
    runSelectedFunction,
    getServerCreds,
    serverCrudOps,
    getproxiesData,
    proxiesCrudOps,
    getCaptcaData,
    captchaCrudOps,
    AddUser,
    UpdateUserRole,
    getJurisdictions,
    getUsersList,
    UpdateTrackingDetails,
    userManagement,
    GetUsersDetails,
    getServerStatus,
    getJurisdictionsPageData,
    changeJurisdictioStatus,
    getJurisdictionNotes,
    UpdateNotesOrGitLink,
    GetWebsiteLinks,
    getFilteredCounts,
    GetTotalWebistesAndCategories,
    GetMonitorsAndDevelopers,
    getJurisdictionsByUser,
    UpdateUsername,
    mailingServices,
    getmailserviceData
  };

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
}
