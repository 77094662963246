import React from 'react';

//import Scss
import './assets/scss/themes.scss';
import "./pages/monitoring/global.css";
import "./pages/monitoring/tables.css";
import "./pages/Settings/settings.css";
import "./pages/DashboardEcommerce/global.css";
import "./pages/Tables/GridTables/tables.css";
import "./pages/MailingServices/mailingservices.css";
import "./pages/Invoice/invoice.css";
import "./pages/Proxies/proxies.css";
import "./pages/Jurisdictions/jurisdictions.css";
import "./pages/Captcha/captcha.css";
import "./pages/BaseUi/UiCards/masonry.css";
import "./pages/allUsers/style.css";
import "./pages/Servers/server.css";
import "./main.css"

//imoprt Route
import Route from './Routes';

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// Fake Backend 
import fakeBackend from "./helpers/AuthType/fakeBackend";

// Activating fake backend
fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

function App() {
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
